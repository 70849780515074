// vendors
import React from 'react';
import SEO from '@src/components/SEO';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

// views
import ContentSection from '../views/AboutPageView/ContentSection/ContentSection';
import HeroSection from '../views/AboutPageView/HeroSection';

const AboutPage = () => {
  const langLinks = [{ langKey: 'fr', href: '/a-propos' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang='en'
        langLinks={langLinks}
        title='Particuliers'
        description='Besoin de solutions de rangement domestique durables et de première qualité? Les produits Permafil sont robustes, polyvalents et s’adaptent à votre espace.'
      />

      <HeroSection>
        <h1>About us</h1>
      </HeroSection>

      <ContentSection>
        <h2>History</h2>

        <p>
          Permafil was founded in 1989 by a Quebec entrepreneur, who envisioned
          to become a major player in the manufacture of home storage products.
        </p>

        <p>
          Thirty years later, Permafil is the only wire shelving manufacturer in
          Canada. We have extended its expertise into various categories of
          products and services. Permafil is a dominant player in eastern Canada
          in the manufacturing and installation of wire shelving, partitions and
          wire mesh storage systems, bicycle racks, melamine storage shelving
          and furniture, and commercial displays.
        </p>

        <p>
          A change in shareholding occurred in 2009—and we are proud that
          Permafil is still owned by local entrepreneurs. It is active primarily
          in the construction world in which it is recognized as a reliable
          partner manufacturing and installing quality products and providing
          first-class service through a professional team devoted to customer
          satisfaction.
        </p>

        <p>
          Permafil works with the biggest players in the construction industry
          in Quebec and eastern Ontario in the vast majority of multi-unit
          condo, rental or retirement home construction projects. Permafil also
          serves several major players in the hardware market and also sells on
          the web through its{' '}
          <a href='https://www.wallysystem.com/en'>www.wallysystem.com</a>{' '}
          online store.
        </p>

        <p>
          In addition, the commercial display unit division has enjoyed
          significant success for several years with major manufacturers who,
          together with Permafil, develop merchandising and display solutions
          for their products throughout North America.
        </p>

        <p>
          Permafil’s management is determined to maintain the sustained sales
          growth it has enjoyed in recent years, and is constantly working to
          develop new products and markets to ensure the company’s growth and
          sustainability.
        </p>

        <hr />

        <h2>Mission</h2>

        <p>
          Everything Permafil does is part of our pursuit to provide a better
          customer experience in all business sectors in which we operate.
        </p>

        <hr />

        <h2>Our values</h2>

        <h3>Integrity</h3>
        <p>
          Act with exemplary honesty towards our customers, shareholders,
          suppliers and employees in order to maintain their trust at the
          highest level.
        </p>

        <h3>Total customer satisfaction: exceptional service</h3>
        <p>
          Provide solutions that respect and meet our external and internal
          customers’ expectations through communication, our ability to respond
          and the timely delivery of high-quality products and reliable
          services.
        </p>

        <h3>
          Foster excellent relationships with our employees and business
          partners
        </h3>
        <p>
          Create an exceptional work environment that facilitates open
          communication, development, opportunities for advancement and fair
          practices in which our employees’ achievements are recognized. To
          maintain a relationship of mutual trust with our business partners.
        </p>

        <h3>Manufacture premium products with no compromising</h3>
        <p>
          Deliver a product that meets recognized standards and our customers
          expectations through continuous improvement procedures without
          compromising in materials, design and manufacture.
        </p>

        <h3>High-efficiency manufacturing</h3>
        <p>
          Maintain optimal process efficiency by implementing the best business
          practices in our manufacturing plant.
        </p>

        <h3>Provide a safe, clean and orderly work environment</h3>
        <p>
          Develop employee engagement and take all steps necessary to create a
          safe, healthy and clean work environment with the latest equipment and
          technology.
        </p>

        <h3>Be a good corporate citizen</h3>
        <p>
          Comply with laws and regulations, protect the environment and
          encourage the company and its employees to get involved in their
          community.
        </p>

        <hr />

        <h2>
          <Link
            to='/en/protection-private-information'
            css={`
              color: inherit;
              text-underline-offset: 4px;

              :hover,
              :focus {
                text-decoration: none;
              }
            `}
          >
            Protection of private information
          </Link>
        </h2>
      </ContentSection>
    </Layout>
  );
};

export default AboutPage;
